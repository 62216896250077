/*
*
* Pagination custom
* --------------------------------------------------
*/

//== Pagination variables
//

$pagination-padding-vertical: 10px;
$pagination-padding-horizontal: 10px;

$pagination-font-size: 14px;
$pagination-lh-size: 20px;
$pagination-border-w: 1px;

$pagination-color:                     $gray-darker;
$pagination-bg:                        $white;
$pagination-border:                    #cdcdcd;

$pagination-hover-color:               $white;
$pagination-hover-bg:                  $primary;
$pagination-hover-border:              $primary;

$pagination-active-color:              $white;
$pagination-active-bg:                 $primary;
$pagination-active-border:             $primary;

$pagination-disabled-color:            rgba($gray-darker, .5);
$pagination-disabled-bg:               #fff;
$pagination-disabled-border:           rgba(#cdcdcd, .5);


//
// Pagination
// --------------------------------------------------
$pagination-size: 52px;
$pagination-offset: 20px;

.page {
  .pagination-custom {
    @include spacing(8px);
  }
}
.pagination-custom {
  position: relative;
  line-height: 0;
  font-size: 0;
  text-align: center;

  li {
    display: inline-block;
    vertical-align: middle;
  }

  li a {
    display: block;
    width: auto;
    min-height: $pagination-size;
    min-width: $pagination-size;
    height: $pagination-size;
    padding: 10px 20px;
    border: 1px solid;
    border-radius: 6px;
    font: 700 14px/24px $font-family-base;
    vertical-align: middle;

    &:after {
      content: '';
      height: 108%;
      width: 0;
      display: inline-block;
      vertical-align: middle;
    }

    &,
    &:active,
    &:focus {
      color: $pagination-color;
      background: $pagination-bg;
      border-color: $pagination-border;
    }

    &:hover {
      color: $pagination-hover-color;
      background: $pagination-hover-bg;
      border-color: $pagination-hover-border;
    }
  }

  li:first-child,
  li:last-child {
    a {
      padding-left: 26px;
      padding-right: 26px;
      font-size: 12px;
      text-transform: uppercase;
    }
  }

  li:first-child a {
    &:before {
      content: '';
    }
  }

  li:last-child a {
    &:before {
      content: '';
    }
  }

  li.disabled,
  li.active {
    pointer-events: none;
  }

  li.active a {
    color: $pagination-active-color;
    background: $pagination-active-bg;
    border-color: $pagination-active-border;
  }

  li.disabled a {
    color: $pagination-disabled-color;
    background: $pagination-disabled-bg;
    border-color: $pagination-disabled-border;
  }
}


* + .pagination-custom {
  margin-top: 35px;
}

@media (min-width: $screen-sm-min) {
  * + .pagination-custom {
    margin-top: 60px;
  }
}



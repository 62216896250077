/*
*
* Animate.css
* --------------------------------------------------
*/


.animated {
  -webkit-animation-duration: 1.4s;
  animation-duration: 1.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  opacity: 1;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

html:not(.lt-ie10) .not-animated {
  opacity: 0;
}

/**
* FadeIn Keyframes Animation
*/

@include keyframes(fadeIn) {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

/**
* FadeInUp Keyframes Animation
*/

@include keyframes(fadeInUp) {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

/**
* FadeInDown Keyframes Animation
*/

@include keyframes(fadeInDown) {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -20%, 0);
    transform: translate3d(0, -20%, 0);
  } 

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

/**
* FadeInLeft Keyframes Animation
*/

@include keyframes(fadeInLeft) {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-15%, 0, 0);
    transform: translate3d(-15%, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

/**
* FadeInRight Keyframes Animation
*/

@include keyframes(fadeInRight) {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(40%, 0, 0);
    transform: translate3d(40%, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

/*
**
* FadeOut Keyframes Animation
*/

@include keyframes(fadeOut) {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

/**
* SlideInDown Keyframes Animation
*/

@include keyframes(slideInDown) {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

/**
* SlideInLeft Keyframes Animation
*/

@include keyframes(slideInLeft) {
  0% {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}

/**
* SlideInRight Keyframes Animation
*/

@include keyframes(slideInRight) {
  0% {
    -webkit-transform: translate3d(40%, 0, 0);
    transform: translate3d(40%, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}

/**
* SlideOutDown Keyframes Animation
*/

@include keyframes(slideOutDown) {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  100% {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

.slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown;
}

// custom transform rotate

.rotate-custom {
  
  transform-style: preserve-3d;
  animation-duration: 2s;
  
  &-left {
    -webkit-animation-name: rotate-custom-left;
    animation-name: rotate-custom-left;
  }
  &-right {
    -webkit-animation-name: rotate-custom-right;
    animation-name: rotate-custom-right;
  }
}

@include keyframes(rotate-custom-left) {
  0% {
    transform: perspective(1000px) rotate3d(0,1,0,50deg) translateY(15%);
    opacity: 0;
  }
  100% {
    transform: perspective(1000px) rotate3d(0,1,0,0deg) translateY(0);
    opacity: 1;
  }
}
@include keyframes(rotate-custom-right) {
  0% {
    transform: perspective(1000px) rotate3d(0,1,0,-50deg) translateY(15%);
    opacity: 0;
  }
  100% {
    transform: perspective(1000px) rotate3d(0,1,0,0deg) translateY(0);
    opacity: 1;
  }
}

// custom blur in
@include keyframes(blurIn){
  0% {
    opacity: 0;
    filter: blur(7px);
    transform: scale3d(1.20, 1.2, 1.2);
  }

  100% {
    opacity: 1;
    filter: blur(0);
    transform: scale3d(1,1,1);
  }
}

.blurIn {
  -webkit-animation-name: blurIn;
  animation-name: blurIn;
  animation-duration: 1.1s;
}
/*
*
* Page Loaders
* --------------------------------------------------
*/


// Base styles
// -------------------------

.page-loader {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  z-index: 9999999;
  background: #fff;
  transition: .3s all ease;

  &.loaded {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
  }

  &.ending {
    display: none;
  }

  .page-loader-body {
    text-align: center;
  }
}

// Custom Styles
// -------------------------
/* EDITABLE PARAMETERS */

$body-col: #fff;
$handles-col: #353535;
$joint-col: #fff;
$blade-col: #353535;

/* handle width */
$hw: 2em;
/* blade width */
$bw: 6em;
/* blade's height and handle's thickness will be inversely proportional to $div */
$div: 5.5;
/* initial amplitude between blades */
$angle: 20deg;
/* final amplitude between blades */
$f-angle: 80deg;

/* NON EDITABLE PARAMETERS */

/* handle height */
$hh: 2em;
/* handle border thickness */
$ht: $hh / $div;
/* blade height */
$bh: $ht;
/* joint diameter */
$dj: $bh * .8;

/* left position of the center of rotation */
$lp: $hw + 2 * $bh + $dj;
/* left position in % of the center of rotation */
$lpp: $lp * 100% / ($bw + $hw);
/* left position of the joint in % */
$lpj: $lpp - ($dj * 100% / ($bw + $hw) / 2);

body {
  background: $body-col;
}

.scissors {
  position: relative;
  width: $bw + $hw;
  height: 2 * $hh - $bh;
  margin: $bw/2 auto;
}

.half {
  width: $bw + $hw;
  height: $hh;
  position: absolute;
  top: 0;
  left: 0;

  transform-style: preserve-3d;
  backface-visibility: hidden;
  transform-origin: $lpp 100% 0;
  transform: rotate($angle / 2);
  animation: rot-up 1s ease-out infinite;


  &:nth-child(even) {
    top: $hh - $bh;
    transform-origin: $lpp 0% 0;
    transform: rotate(-$angle / 2);
    animation: rot-down 1s ease-out infinite;
  }

  &:nth-child(even) .blade {
    top: 0;
    border-radius: 0 $bh 0 0;

    &:after {
      top: $bh;
      border-style: solid;
      border-width: $bh $bh * 2 0 $bh * 2;
      border-color: $blade-col transparent transparent transparent;
    }
  }
}

@mixin rot($a, $b) {
  0% { transform: rotate($a); }
  50% { transform: rotate($b); }
  100% { transform: rotate($a); }
}

@keyframes rot-up {
  @include rot($angle / 2, $f-angle / 2);
}

@keyframes rot-down {
  @include rot(-$angle / 2, -$f-angle / 2);
}

.joint {
  width: $dj;
  height: $dj;
  border-radius: 50%;
  background: $joint-col;
  position: absolute;
  top: $hh - $bh / 2 - $dj / 2;
  left: $lpj;
}

.handle {
  width: $hw;
  height: $hh;
  border-radius: $hh / 2;
  border: $ht solid $handles-col;
  background: $body-col;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
}

.blade {
  width: $bw;
  height: $bh;
  position: absolute;
  top: $hh - $bh;
  left: $hw;
  background: $blade-col;
  border-radius: 0 0 $bh 0;

  &:before {
    content: '';
    width: $hw / 2;
    height: $bh;
    background: $blade-col;
    position: absolute;
    top: 0;
    left: -$hw / 2;
  }

  &:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 $bh * 2 $bh $bh * 2;
    border-color: transparent transparent $blade-col transparent;
    position: absolute;
    top: -$bh;
    left: -$bh * 2;
  }
}
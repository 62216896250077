/*
*
* Navigation
* --------------------------------------------------
*/

.navigation{

}


.navigation-pills{
  li{
    a{
      display: inline-block;

      padding: 3px 9px;
      border: 1px solid $primary;
      border-radius: 5px;

      font-size: 16px;
      transition: .2s;

      &.active,
      &:hover,
      &:focus{
        background-color: $primary;
        color: $white;
      }
    }
  }
}
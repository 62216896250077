/*
*
* Progress Bars
* --------------------------------------------------
*/



// Linear Progress Bars
// --------------------------------------------------

.progress-linear {
  position: relative;
  text-align: left;

  .progress-header{
    @include display-flex();
    @include align-items(center);
    @include justify-content(space-between);
  }

  .progress-bar-linear-wrap, .progress-bar-linear {
    height: 100%;
    border-radius: 3px;
  }

  .progress-bar-linear-wrap {
   background: $gray-lighter;
    height: 10px;
  }

  .progress-bar-linear {
    background-color: $primary;
    width: 0;
    transition: .5s all ease-in-out;
  }

  .progress-value{
    &:after{
      content:"%";
    }
  }
}

.progress-linear  + .progress-linear {
  margin-top: 15px;
}



//----------------------------------------------------

//
// ProgressBars
// --------------------------------------------------

.progress-bar-js {
  position: relative;
  width: 100%;

  .progress-bar__body {
    white-space: nowrap;
    font: 400 12px/18px $font-family-base;
    color: $gray-base;

    &:after {
      content: '%';
    }
  }

  .progress-bar__stroke,
  .progress-bar__trail {
    stroke-linejoin: round;
  }
}

.progress-bar-horizontal {
  @include display-flex;
  @include flex-direction(row);
  @include flex-wrap(nowrap);
  @include align-items(center);
  text-align: left;

  > * {
    margin: 0;
  }

  > svg {
    height: 10px;
    @include flex-grow(1);
  }

  .progress-bar__body {
    margin-left: 10px;
    width: 30px !important;
  }
}

.progress-bar-radial {
  position: relative;
  padding-bottom: 100%;

  > svg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 5px;
    overflow: hidden;
  }

  .progress-bar__stroke,
  .progress-bar__trail {
    stroke-location: outside;
  }

  .progress-bar__body {
    transform: translate(50%, -50%);
  }
}

.progress-bar-default {
  .progress-bar__stroke {
    stroke: $gray;
  }

  .progress-bar__trail {
    stroke: rgba($gray, 0.05);
  }
}

.progress-bar-primary {
  .progress-bar__stroke {
    stroke: $primary;
  }

  .progress-bar__trail {
    stroke: $gray-2;
  }
}


.progress-bar-radial-default {
  text-align: center;
  &-header {
    letter-spacing: .05em;
    text-transform: uppercase;
    color: $gray;
  }
}

.progress-bar-radial-default-wrap {
  max-width: 182px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  .progress-bar-radial {
    position: relative;
    padding-bottom: 100%;

    svg,
    .progress-bar__body {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
    }

    svg {
      height: auto;
    }

    .progress-bar__body {
      right: 10px;
      font-size: 36px;
      font-weight: 500;
      line-height: 1;
      color: $gray-base;

      @media (min-width: $screen-sm-min) {
        font-size: 48px;
      }
    }
  }
}

.progress-bar-bs {
  position: relative;
  padding-right: 60px;
  height: auto;
  margin: 0;

  .progress {
    height: 10px;
    margin-bottom: 0;
    box-shadow: none;
    border-radius: 20px;
  }

  .progress-bar {
    box-shadow: none;
    border: 0;
    border-radius: 20px;
  }

  .caption {
    position: absolute;
    bottom: -6px;
    right: 5px;
    width: 60px;
    padding-left: 20px;
    text-transform: uppercase;
    color: $gray;
  }
}

.progress-variant-primary {
  .progress {
    background: $gray-light;
  }

  .progress-bar {
    background: $secondary;
  }
}

// Mixins

// Clearfix
@mixin clearfix() {
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

// Disable padding - top on two similar backgrounds
@mixin bg-behaviour($bg-color) {
  background-color: $bg-color;

  & + & {
    padding-top: 0;
  }
}

// Text variant
@mixin text-variant($parent, $color, $hover-color) {
  #{$parent} {
    color: $color;
  }
  a#{$parent}:hover,
  a#{$parent}:focus {
    color: $hover-color;
  }
}

// Reset default button styles
@mixin reset-button {
  background: none;
  border: 0;
  color: inherit;
  cursor: pointer;
  font: inherit;
  overflow: hidden;
  padding: 0;
  position: relative;
  //touch-action: manipulation;
  //user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  outline: none;

  &:active {
    cursor: wait;
  }
}

// Spacing
//
@mixin spacing($x, $y : $x) {
  position: relative;
  transform: translateY(-$y);
  margin-bottom: -$y;
  margin-left: - round($x / 2);
  margin-right: - round($x / 2);

  > * {
    margin-top: $y;
    padding-left: round($x / 2);
    padding-right: round($x / 2);
  }
}

// Link
//
@mixin link($default-clr, $hover-color) {
  &,
  &:active,
  &:focus {
    color: $default-clr;
  }

  &:hover {
    color: $hover-color;
  }
}

// Triangle

@mixin triangle($dir, $w, $h, $clr) {
  width: 0;
  height: 0;
  border-style: solid;

  @if $dir == top {
    border-width: 0 ceil($w/2) $h ceil($w/2);
    border-color: transparent transparent $clr transparent;
  } @else if $dir == bottom {
    border-width: $h ceil($w/2) 0 ceil($w/2);
    border-color: $clr transparent transparent transparent;
  } @else if $dir == left {
    border-width: ceil($h/2) $w ceil($h/2) 0;
    border-color: transparent $clr transparent transparent;
  } @else if $dir == right {
    border-width: ceil($h/2) 0 ceil($h/2) $w;
    border-color: transparent transparent transparent $clr;
  } @else if $dir == top_left {
    border-width: $w $w 0 0;
    border-color: $clr transparent transparent transparent;
  } @else if $dir == top_right {
    border-width: 0 $w $w 0;
    border-color: transparent $clr transparent transparent;
  } @else if $dir == bottom_right {
    border-width: 0 0 $w $w;
    border-color: transparent transparent $clr transparent;
  } @else if $dir == bottom_left {
    border-width: $w 0 0 $w;
    border-color: transparent transparent transparent $clr;
  }
}

// Grid related values
//
@mixin make-grid-parameter($parameter, $offset, $container, $col) {
  @if $offset > 0 {
    #{$parameter}: calc(50% - #{$container} / 2 + #{$offset} + (#{$container} / #{$cell-count}) * #{$col});
  } @else if ($offset < 0) {
    #{$parameter}: calc(50% - #{$container} / 2 - #{-$offset} + (#{$container} / #{$cell-count}) * #{$col});
  } @else {
    #{$parameter}: calc(50% - #{$container} / 2 + (#{$container} / #{$cell-count}) * #{$col});
  }
}
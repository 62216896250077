/**
 *	This element is created inside your target element
 *	It is used so that your own element will not need to be altered
 **/
.time_circles {
  position: relative;
  width: 100%;
  height: 100%;
}

/**
 *	This is all the elements used to house all text used
 * in time circles
 **/
.time_circles > div {
  position: absolute;
  text-align: center;
  font-family: $font-family-sec;
  top: 50%!important;
  transform: translateY(-100%)!important;
  -webkit-transform: translateY(-100%)!important;

  @media (min-width: 640px) {
    transform: translateY(-123%) !important;
    -webkit-transform: translateY(-123%) !important;
  }
}

@media (min-width: $screen-lg) {
  #DateCountdown{
    width: 100%;
  }
}

/**
 *	Titles (Days, Hours, etc)
 **/
.time_circles > div > h4 {
  margin: 0;
  padding: 0;
  text-align: center;

  //color: $gray-dark;
  font-family: $font-family-base;
  font-size: 14px !important;
  position: absolute;
  text-transform: uppercase;
  color: $gray;
  font-weight: 400;
  bottom: -60px;
  left: 0;
  right: 0;

  & + *{
    margin-top: 0;
  }
  @media (min-width: 410px) {
    bottom: -75px;
  }
  @media (min-width: 520px) {
    bottom: -80px;
  }

  @media (min-width: 640px) {
    bottom: -35px;
  }
  @media (min-width: $screen-lg-min) {
    bottom: -40px;
  }
}

/**
 *	Time numbers, ie: 12
 **/
.time_circles > div > span {
  display: block;
  font-size: 17px;
  text-align: center;
  font-weight: 500;
  font-family: $font-family-sec;
  color: $gray-darker;

  @media (min-width: $screen-sm) {
    font-size: 30px;
  }

  @media (min-width: $screen-lg) {
    font-size: 45px !important;
  }

  @media (min-width: $screen-xl-min) {
    font-size: 60px !important;
  }

  .DateCountdown-1 & {
    color: $gray-base;
  }
}


/*
*
* Owl Carousel
* --------------------------------------------------
*/

.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* 
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/* 
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
  
  * + & {
    margin-top: 30px;
  }
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
  cursor: pointer;
  user-select: none;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel .owl-refresh .owl-item {
  display: none;
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/* 
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease;
}

/* 
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  font: 400 40px/80px 'FontAwesome';
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: scale 100ms ease;
  &:before {
    content: '\f144';
  }
}

.owl-carousel .owl-video-play-icon:hover {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
}

/*
 * Owl Navigation
 */

.owl-nav {

}

.owl-prev, .owl-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font: 400 46px/50px 'FontAwesome';
  color: $white;
  pointer-events: auto;

  &:hover {
    color: $secondary-2;
  }
}

.owl-prev {
  left: 10px;

  &:before {
    content: '\f104';
  }
}

.owl-next {
  right: 10px;

  &:before {
    content: '\f105';
  }
}

/*
 * Owl Pagination
 */

.owl-dots {
  text-align: center;
  margin-top: 10px;
}

.owl-dot {
  position: relative;
  width: 7px;
  height: 7px;
  display: inline-block;
  border-radius: 100%;
  background: $gray-9;
  transition: .2s;

  text-align: center;
  outline: none;
  cursor: pointer;

  &:before{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    content: "";
    display: inline-block;
    border: 2px solid $secondary;
    border-radius: 50%;
    opacity: 0;
    transition: 250ms;
  }

  &:hover,
  &:focus {
    &:before{
      top: -7px;
      bottom: -7px;
      right: -7px;
      left: -7px;
      opacity: 1;
    }
  }

  &.active {
    &:before{
      top: -7px;
      bottom: -7px;
      right: -7px;
      left: -7px;
      opacity: 1;
    }
  }

  & + & {
    margin-left: 20px;
  }
}

.owl-carousel.owl-carousel-light-dots {
  .owl-dot {
    border-color: $white;

    &:hover,
    &:focus {
      background-color: $white;
    }

    &.active {
      background-color: $white;
    }
  }
}

.owl-carousel {
  // Nav
  .owl-nav {
    position: absolute;
    top: 50%;
    width: 100%;
    left: 50%;
    display: flex;
    justify-content: space-between;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }

  .owl-button-next,
  .owl-button-prev {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: $primary;
    font-size: 21px;
    transition: 350ms;
    cursor: pointer;
    color: $white;
    pointer-events: auto;
    &:hover {
      background-color: $secondary;
    }
  }
  .owl-button-next {
    &:before {
      position: relative;
      right: -1px;
    }
  }
  .owl-button-prev {
    &:before {
      position: relative;
      left: -1px;
    }
  }
}

// owl centered image

.owl-centered {
  margin-top: 20px;
  
  &.owl-carousel .owl-stage-outer {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .owl-item {
    will-change: transform;
    .portfolio-item {
      transition: 550ms;
    }
  }

  .owl-nav {
    max-width: 370px;
  }  

  @media (min-width: $screen-xs-min) {
    .owl-nav {
      max-width: 430px;
    }
  }

  @media (min-width: $screen-sm-min) {
    .owl-item.active.center {
      z-index: 2;
      .portfolio-item {
        transform: scale3d(1.1, 1.1, 1.1);
        box-shadow: 0 0 13px rgba($gray-base, .29);
      }
    }

    .owl-nav {
      width: calc(100vw / 3 + 100px);
      max-width: 100%;
    }
  }
}


// carousel with stage padding
.owl-carousel-stagePadding {
  * + & {
    margin-top: 30px;
  }
  @media (min-width: $screen-lg-min) {
    * + & {
      margin-top: 50px;
    }
  }
  @media (min-width: $screen-xl-min) {
    .owl-controls {
      display: none;
    }
  }
}

// carousel condensed
.owl-carousel-condensed {
  .owl-item {
    will-color: transform;  
  }
  .owl-controls {
    .owl-nav {
      padding: 0 20px;
    }
  }
  @media (min-width: $screen-lg-min) {
    .owl-controls {
      display: none;
    }
  }
}

//owl testimonials
.owl-testimonials {
  .owl-stage-outer {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .owl-dots {
    margin-top: 15px;
  }
}